import Vuetify from '@/plugins/vuetify'

const i18n = {
  state: {
    language: Vuetify.framework.lang.current, // Language Code for Vuetify i18n Module
    socket_language: null // Language name
  },
  mutations: {
    SET_I18N_LANGUAGE(state, language) {
      state.socket_language = language
      switch (language) {
        case 'cantonese':
          state.language = 'zhHant'
          Vuetify.framework.lang.current = 'zhHant'
          break
        case 'mandarin':
          state.language = 'zhHans'
          Vuetify.framework.lang.current = 'zhHans'
          break
        case 'english':
          state.language = 'en'
          Vuetify.framework.lang.current = 'en'
          break
      }
    }
  }
}

export default i18n
