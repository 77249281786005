<template>
  <v-row no-gutters class="top-bar" align="center" style="height: 4.5rem" :style="`background: ${config.top_bar_background_color}`">
    <!-- icon -->
    <v-col cols="auto" style="height: 2.5rem; width: 3rem">
      <v-img class="top-bar-img" :src="config.logo_url" contain>
        <template v-slot:placeholder>
          <v-skeleton-loader type="image" />
        </template>
      </v-img>
    </v-col>

    <!-- title -->
    <v-col cols="auto" style="width: calc(100% - 11rem)">
      <v-row no-gutters :style="`color: ${config.text_color}`">
        <span class="top-bar-title ellipsis-text">{{ top_bar_title }}</span>
      </v-row>
      <v-row no-gutters :style="`color: ${config.text_color}`">
        <span class="top-bar-subtitle ellipsis-text">{{ top_bar_subtitle }}</span>
      </v-row>
    </v-col>

    <!-- language -->
    <v-col cols="auto" v-if="this.$route.path === '/chat'">
      <v-menu ref="language_menu" transition="slide-y-transition" origin="top center" offset-y bottom nudge-bottom="1" close-on-content-click content-class="language-menu">
        <template v-slot:activator="{ on, attrs }">
          <v-chip outlined small class="language-chip" :style="`color: ${config.text_color}`" v-bind="attrs" v-on="on"><v-icon>mdi-web</v-icon>{{ language }}</v-chip>
        </template>
        <v-card style="padding: 0.5rem 0">
          <v-list>
            <v-list-item v-for="(item, i) in language_list" :key="i" class="language-menu-text" @click="updateLanguage(item.payload)">{{ item.short_name }}</v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import global_config from '@/../config.json'
import { mapState } from 'vuex'

export default {
  name: 'Header',
  data() {
    return {
      config: global_config
    }
  },
  computed: {
    ...mapState(['i18n']),
    language() {
      let socket_language = this.i18n.socket_language
      let language_shortname = this.config.languages.filter((language) => language.payload === socket_language)[0].short_name

      return language_shortname
    },
    language_list() {
      return this.config.languages
    },
    top_bar_title() {
      let socket_language = this.i18n.socket_language
      return this.config[`top_bar_title_${socket_language}`]
    },
    top_bar_subtitle() {
      let socket_language = this.i18n.socket_language
      return this.config[`top_bar_subtitle_${socket_language}`]
    }
  },
  methods: {
    updateLanguage(language) {
      this.$store.commit('SET_CHAT_LOADING', true)
      this.$store.commit('SET_I18N_LANGUAGE', language)
      this.$store.dispatch('updateSocketServer')
      this.$store.commit('SET_CHAT_HISTORY', [])
    }
  }
}
</script>

<style scoped>
.top-bar {
  padding: 0 1rem;
}

.top-bar-img {
  margin-right: 0.5rem;
  max-width: 2.5rem;
  border-radius: 1.25rem;
  /* animation: fade 0.75s; */
}

.top-bar-title {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.375rem;
  animation: fade 0.75s;
}

.top-bar-subtitle {
  font-weight: 400;
  font-size: 0.6875rem;
  line-height: 0.9375rem;
  animation: fade 0.75s;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.v-list-item {
  min-height: 2rem !important;
  height: 2rem !important;
}

.language-menu {
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.3125rem rgba(0, 0, 0, 0.14), 0 0.0625rem 0.625rem rgba(0, 0, 0, 0.12), 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2) !important;
}

.language-chip {
  margin-left: 1rem;
  border: 0.0625rem solid #ffffff;
  position: absolute;
  top: 1rem;
  right: 3.75rem;
}

.language-menu-text {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  letter-spacing: 0.01875rem;
  color: #1b1b1b;
  padding: 0.3125rem 1rem;
  cursor: pointer;
}
</style>
